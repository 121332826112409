import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
// import variables
import { breakpoints, colors } from '../../style-utilities/variables';

const StyledMenuToggle = styled.button`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-area: MenuToggle;
  background-color: ${colors.white};
  background: transparent;
  border: 0;
  outline: none;
  font-size: 100%;
  box-shadow: none;
  color: ${colors.primaryGray};
  .svg-inline--fa {
    max-width:28px;
  }
  @media (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;
StyledMenuToggle.displayName = 'StyledMenuToggle';

const MenuToggle = ({ menuIsOpen, toggleMenu }) => {
  return menuIsOpen ? (
    <></>
  ) : (
    <StyledMenuToggle onClick={toggleMenu}>
      <FontAwesomeIcon icon={faBars} pull="right" size="2x" />
    </StyledMenuToggle>
  );
};

export default MenuToggle;