import React, { Component } from 'react';
import styled from 'styled-components';
import { colors, breakpoints } from '../style-utilities/variables';
import Arrow from './Arrow';

const StyledAlertBanner = styled.div`
  width: 100vw;
  background: black;
  text-align: center;
  padding: 5px 0;
  span {
    color: white;
  }
  a {
    margin-left: 4px;
    color: white;
    font-weight: bold;
  }
  .arrow {
    margin-left: 6px;
    svg {
      height: 16px;
      width: 16px;
      margin-top: -2px;
      fill: white;
    }
  }
`;

const GlobalAlertBanner = ({content}) => {
  if (content.headline !== "" && content.headline_copy === "" || content.cta_text !== "" && content.cta_text_copy === "") {
    return (
      <StyledAlertBanner className="alert-banner">
        <span className="alert-header">{content.headline} </span>
        <a href={content.cta_url}>{content.cta_text}</a>
        <Arrow color="white" />
      </StyledAlertBanner>
    )
  } else if (content.headline && content.headline_copy !== "" || content.cta_text && content.cta_text_copy !== "") {
    return (
      <StyledAlertBanner className="alert-banner">
        <div className="ticker-item">
          <span className="alert-header">{content.headline} </span>
          <a href={content.cta_url}>{content.cta_text}</a>
          <Arrow color="white" />
        </div>
        <div className="ticker-item">
          <span className="alert-header">{content.headline_copy} </span>
          <a href={content.cta_url_copy}>{content.cta_text_copy}</a>
          <Arrow color="white" />
        </div>
      </StyledAlertBanner>
    )
  } else {
    return null;
  }

}

export default GlobalAlertBanner;
