// Action Types
export const FETCH_TAG_COLORS_REQUEST = "ansira/tags/FETCH_TAG_COLORS_REQUEST";
export const FETCH_TAG_COLORS_SUCCESS = "ansira/tags/FETCH_TAG_COLORS_SUCCESS";
export const FETCH_TAG_COLORS_FAILURE = "ansira/tags/FETCH_TAG_COLORS_FAILURE";

// Action Creators
export function fetchTagColors() {
  return {
    type: FETCH_TAG_COLORS_REQUEST
  };
}

// set initial state
const initialState = {};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_TAG_COLORS_REQUEST:
      return {
        ...initialState,
        requestPending: true
      };
    case FETCH_TAG_COLORS_SUCCESS:
      return {
        tagColors: action.tagColors,
        requestPending: false
      };
    default:
      return state;
  }
}
