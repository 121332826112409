import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { breakpoints, colors } from '../style-utilities/variables';
// import components
import NavItem from './NavItem';

const StyledMainNav = styled.nav`
  grid-area: MainNav;
  -ms-grid-column: 4;
  -ms-grid-column-span: 7;
  height: 71px;
  position: relative;
  .contact-mobile {
    padding: 8px 0;
    a {
      padding: 0;
      font-weight: bold;
      font-size: 22px;
    }
  }
  @media screen and (min-width: ${breakpoints.desktop}) {
    background: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: space-around;
    .contact-mobile {
      display: none;
    }
  }
`;

const MainNav = ({ menu, children, menuIsOpen, hideMenu, className }) => {
  const renderMenuItems = () =>
    menu.map(item => (
      <NavItem
        key={item.object_slug}
        item={item}
        menuIsOpen={menuIsOpen}
        hideMenu={hideMenu}
        className={className}
      />
    ));

  return (
    <StyledMainNav>
      {renderMenuItems()}
      <div className="contact-mobile">
        <Link to="/contact" onClick={() => this.props.hideMenu()}>
          Contact Us
        </Link>
      </div>
      {children}
    </StyledMainNav>
  );
};

export default MainNav;
