import React from 'react';
import CookieConsent from 'react-cookie-consent';
import ReactHtmlParser from 'react-html-parser';

export default class CookieConsentBanner extends CookieConsent {
  render() {
    return (
      <CookieConsent
        location="bottom"
        buttonText={this.props.content.button_text}
        cookieName="cookieConsentBanner"
        style={{
          background: '#000000'
        }}
        buttonStyle={{
          color: '#ffffff',
          fontSize: '14px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          letterSpacing: '3px',
          background: '#ed3424',
          borderRadius: '52px',
          padding: '13px 25px'
        }}
        expires={150}
      >
        {ReactHtmlParser(this.props.content.body_text)}
      </CookieConsent>
    );
  }
}
