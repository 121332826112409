import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../style-utilities/variables";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";

const StyledFooter = styled.footer`
  background: ${colors.black};
  padding-top: 16px;
  padding-bottom: 0;
  display: grid;
  .footer-container {
    max-width: 1500px;
    margin: 0 auto;
  }
`;

const Footer = ({ menu }) => {
  return (
    <StyledFooter className="page-container">
      <div className="footer-container">
        <TopNav menu={menu} />
        <BottomNav />
        {/* HubSpot Embed Code */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/3224127.js"
        ></script>
      </div>
    </StyledFooter>
  );
};

export default Footer;
