import React from "react";
import ColumnItems from "./ColumnItems";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";
import EmailSignupForm from "../EmailSignupForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare
} from "@fortawesome/free-brands-svg-icons";

const StyledFooterNav = styled.div`
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 26px;


  @media (min-width: ${breakpoints.tablet }) {
    grid-template-columns: 1fr 1fr 1fr;
    .newsletter-signup {
      padding: 0 10px;
    }
  }

  .newsletter-signup {
    grid-column: span 2 / auto;
    @media (min-width: ${breakpoints.desktop}) {
      grid-column: span 1 / auto;
    }
  }

  h5 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
    color: ${colors.primaryGray};
  }

  .social {
    margin: 10px 0;
    max-height: 22px;
    overflow: hidden;
    a {
      color: ${colors.primaryGray};
      margin-right: 20px;
      overflow: hidden;
    }
  }
  .companies {
    grid-column: span 2 / auto;
    color: ${colors.primaryGray};
    p {
      margin-bottom: 8px;
      letter-spacing: 0.75px;
    }
    @media(min-width: ${breakpoints.tablet}) {
      grid-column: span 1 / auto;
      border-left: 1px solid #242a30;
      padding-left: 10px;
      margin-left: -1px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    grid-template-columns: 1fr repeat(6, 1fr) 2fr;
    grid-row-gap: 0;
    .companies {
      grid-column-start: 8;
    }
  }
  /* ie10+ specific css */
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
  -ms-grid-rows: 100px 1fr;
  div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row-span: 2;
  }
  div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row-span: 2;
  }
  div:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row-span: 2;
  }
  div:nth-child(4) {
    -ms-grid-column: 4;
    -ms-grid-row-span: 2;
  }
  div:nth-child(5) {
    -ms-grid-column: 5;
    -ms-grid-row-span: 2;
  }
  div:nth-child(6) {
    -ms-grid-column: 6;
    -ms-grid-row-span: 2;
  }
  div:nth-child(7) {
    -ms-grid-column: 7;
    -ms-grid-row-span: 2;
  }
  div:nth-child(8) {
    -ms-grid-column: 8;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }
  .companies {
    -ms-grid-column: 8;
    -ms-grid-row: 2;
  }
`;

const FooterNav = ({ menu }) => {
  const renderMenu = () =>
    menu.map(columnMenu => {
      if (columnMenu["wordpress_children"]) {
        columnMenu["wordpress_children"].forEach((child, index) => {
          columnMenu["wordpress_children"][index]["parent_slug"] =
            columnMenu["object_slug"];
        });
      }
      return <ColumnItems key={columnMenu.object_slug} menu={columnMenu} />;
    });
  return (
    <StyledFooterNav className="top-level">
      {renderMenu()}
      <div className="newsletter-signup">
        <div className="signupform">
          <EmailSignupForm formatType="red-button" />
        </div>
        <div className="social">
          <a
            className="social"
            href="https://twitter.com/ansira"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitterSquare} />
          </a>
          <a
            className="social"
            href="https://www.facebook.com/Ansira-112545888830719/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a
            className="social"
            href="https://www.linkedin.com/company/ansira/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>


      </div>
      <div className="companies">
          <h5>ANSIRA COMPANIES</h5>
          <p>BrightWave</p>
          <p>Local Biz Now</p>
          <p>Sincro</p>
        </div>
    </StyledFooterNav>
  );
};

export default FooterNav;
