import React, { Component } from 'react';
import { navigate } from '@reach/router';

const SearchStateContext = React.createContext('search');
const SearchStateProvider = SearchStateContext.Provider;

export const SearchStateConsumer = SearchStateContext.Consumer;

class SearchState extends Component {
  state = {
    requestPending: false,
    searchTerm: '',
    searchResults: { pages: [], posts: [], caseStudies: [], news: [], whitePapers: [], users: [] },
    updateSearchTerm: async searchTerm => {
      await this.setState({
        searchTerm
      });
    },
    searchSubmit: async () => {
      if (!this.state.searchTerm) {
        return;
      }

      this.setState({
        requestPending: true
      });

      const results = await this.searchWordpress(this.state.searchTerm);

      this.setState(
        {
          searchResults: {
            pages: [...results[0]],
            posts: [...results[1]],
            caseStudies: [...results[2]],
            news: [...results[3]],
            whitePapers: [...results[4]],
            users: [...results[5]],
          },
          requestPending: false
        },
        () => {
          navigate(`/search-results?term=${this.state.searchTerm}`);
        }
      );
    }
  };

  async searchWordpress(searchTerm) {
    const searchMultiplePostTypes = await Promise.all([
      fetch(
        `${
          process.env.GATSBY_API_URI
        }/wp/v2/pages?search=${searchTerm}`
      ),
      fetch(
        `${
          process.env.GATSBY_API_URI
        }/wp/v2/posts?search=${searchTerm}`
      ),
      fetch(
        `${
          process.env.GATSBY_API_URI
        }/wp/v2/case_study?search=${searchTerm}`
      ),
      fetch(
        `${
          process.env.GATSBY_API_URI
        }/wp/v2/news?search=${searchTerm}`
      ),
      fetch(
        `${
          process.env.GATSBY_API_URI
        }/wp/v2/white_paper?search=${searchTerm}`
      ),
      fetch(
        `${
          process.env.GATSBY_API_URI
        }/wp/v2/users?first_name=${searchTerm}`
      )
    ]);

    return await Promise.all(searchMultiplePostTypes.map(res => res.json()));
  }

  render() {
    return (
      <SearchStateProvider value={this.state}>
        {this.props.children}
      </SearchStateProvider>
    );
  }
}

export default SearchState;
