import React, { Component } from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from '../style-utilities/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import SearchForm from './SearchForm';

const StyledSearch = styled.div`
  grid-area: Search;
  -ms-grid-column: 11;
  position: relative;
  width: 50px;
  .search-toggle {
    background: none;
    border: none;
    color: #333;
    height: 65px;
  }
  .hide-x {
    display: none;
  }

  .show-x {
    position: absolute;
    background: ${colors.secondaryGray};
    border-radius: 4px;
    border: none;
    width: 2em;
    height: 2em;
    top: 16px;
    left: 6px;
  }

  /* mobile only styling */
  @media (max-width: ${breakpoints.tabletMax}) {
    button,
    svg {
      display: none;
    }
    input {
      border: 1px solid #ddd;
    }
  }
  /* ie11 styling */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: flex;
    align-items: center;
    justify-content: center;
    .search-toggle {
      margin-left: 0px;
      margin-top: 0px;
      width: 32px;
    }
  }
`;

class Search extends Component {
  state = {
    searchIsOpen: false,
    searchTerm: ''
  };

  toggleSearch = () => {
    this.setState({
      searchIsOpen: !this.state.searchIsOpen
    });
  };

  render() {
    return (
      <StyledSearch>
        <button className="search-toggle" onClick={this.toggleSearch}>
          <FontAwesomeIcon icon={faSearch} pull="right" size="1x" />
        </button>
        <button
          className="close-x"
          onClick={this.toggleSearch}
          className={this.state.searchIsOpen ? 'show-x' : 'hide-x'}
        >
          <FontAwesomeIcon icon={faTimes} size="1x" />
        </button>
        <SearchForm
          searchIsOpen={this.state.searchIsOpen}
          toggleSearch={this.toggleSearch}
          placeholder={this.props.placeholder}
          hideMenu={this.props.hideMenu}
        />
      </StyledSearch>
    );
  }
}

export default Search;
