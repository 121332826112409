// Action Types
export const SEARCH_REQUEST = "ansira/search/SEARCH_REQUEST";
export const SEARCH_SUCCESS = "ansira/search/SEARCH_SUCCESS";
export const SEARCH_FAILURE = "ansira/search/SEARCH_FAILURE";

// Action Creators
export function siteSearch(searchTerm) {
  return {
    type: SEARCH_REQUEST,
    searchTerm
  };
}

// set initial state
const initialState = {};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...initialState,
        requestPending: true
      };
    case SEARCH_SUCCESS:
      return {
        searchResults: action.searchResults,
        requestPending: false
      };
    default:
      return state;
  }
}
