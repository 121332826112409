import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const StyledCloseButton = styled.button`
  border: 0;
  align-self: flex-end;
`;

const CloseButton = ({ toggle }) => {
  return (
    <StyledCloseButton onClick={toggle}>
      {/*<FontAwesomeIcon icon={faTimes} pull="right" size="2x" />*/}
    </StyledCloseButton>
  );
};

export default CloseButton;
