import React, { Component } from 'react';
import styled, { consolidateStreamedStyles } from 'styled-components';
//FORMIK SPECIFIK
import { Helmet } from 'react-helmet';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { colors, breakpoints, fonts } from '../style-utilities/variables';

import Modal from './Modal';
import { timingSafeEqual } from 'crypto';

const Div = styled.div`
  form {
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
  .modal-title {
    font-weight: 700;
    font-size: 26px;
    color: #101820;
    margin-top: 0;
    margin-bottom: 6px;
  }
  .red-button,
  .white-button {
    background-color: ${colors.primary};
    border: 0;
    color: #fff;
    margin: 0 auto;
    border-radius: 25px;
    letter-spacing: 3px;
    outline: none;
    font-size: 14px;
    padding: 14px 26px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    text-transform: uppercase;
  }
  .red-button:hover {
    background-color: ${colors.secondaryRed};
    transition: 0.3s all ease;
  }
  .white-button {
    color: white;
    background: ${colors.primary};
    border: 1px solid white;
  }
  .white-button:hover {
    color: white;
    background-color: ${colors.secondaryRed};
    transition: 0.3s all ease;
  }
  .marginTop {
    margin-top: 10px;
  }
  .form-group {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    line-height: 1rem;
    input[type] {
      /* Remove styles, must use input[type=text] selector to remove iOS box-shadow styles */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid ${colors.primaryGray};
      padding: 0.6rem;
      border-radius: 4px;
    }
    label {
      color: ${colors.black};
      font-weight: bold;
    }
  }
  .label-error {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .field-error {
      font-size: 0.8rem;
      float: right;
      margin-left: 8px;
      color: ${colors.primary};
    }
  }

  .submit-container {
    text-align: center;
    margin-top: 20px;
  }
  .thanks-container {
    text-align: center;
    padding: 50px 0;
  }
  .success-message {
    font-weight: 700;
    font-size: 26px;
    color: #101820;
  }
  .modal-copy {
    color: #545454;
    margin-bottom: 12px;
  }
`;

const initialValues = {
  firstName: '',
  lastName: '',
  email: ''
};

const Fieldset = ({ name, label, children, classname, id, ...rest }) => (
  <div className={classname} id={id}>
    <div className="label-error">
      <label htmlFor={name}>
        {label}
        <ErrorMessage name={name} component="div" className="field-error" />
      </label>
    </div>
    <Field id={name} name={name} {...rest}>
      {children}
    </Field>
  </div>
);

class EmailSignupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBrowser: true,
      windowObject: null,
      emailAddress: '',
      firstName: '',
      lastName: '',
      success: false,
      error: false,
      isModalOpen: false
    };

    // bind functions
    this.closeModal = this.closeModal.bind(this);
    // this.openModal = this.openModal.bind(this);
  }

  // close modal (set isModalOpen, true)
  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  // open modal (set isModalOpen, false)
  openModal() {
    this.setState({
      isModalOpen: true
    });
  }

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined'
    });
    if (this.state.isBrowser) {
      this.setState({ windowObject: window });
    }
  }

  render() {
    return (
      <Div>
        <button
          className={this.props.formatType + ' clickable'}
          onClick={this.openModal.bind(this)}
        >
          EMAIL SIGN-UP
        </button>

        <Modal
          isModalOpen={this.state.isModalOpen}
          closeModal={this.closeModal}
        >
          <p
            style={{ display: this.state.success ? 'none' : 'block' }}
            className="modal-title"
          >
            Get Intel From Ansira
          </p>
          <p
            className="modal-copy"
            style={{ display: this.state.success ? 'none' : 'block' }}
          >
            Receive marketing insights from our experts, plus company updates.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .trim()
                .required('Required'),
              lastName: Yup.string()
                .trim()
                .required('Required'),
              email: Yup.string()
                .trim()
                .email('Required*')
                .required('Required')
            })}
            onSubmit={(values, actions, message) => {
              fetch(
                'https://api.hsforms.com/submissions/v3/integration/submit/3224127/472b5a29-18a4-4b26-828c-fd4e9c2d6e47',
                {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    context: {
                      pageUri: this.state.windowObject.location.href,
                      pageName: 'Newsletter Sign Up'
                    },
                    fields: [
                      {
                        name: 'firstname',
                        value: values.firstName
                      },
                      {
                        name: 'lastname',
                        value: values.lastName
                      },
                      {
                        name: 'email',
                        value: values.email
                      }
                    ]
                  })
                }
              )
                .then(response => {
                  return response.json();
                })
                .then(data => {
                  if (data) {
                    this.setState({
                      success: true,
                      successMsg: 'Thanks for signing up \n for our newsletter',
                      downloadTitle: ''
                    });
                  }
                });

              actions.setSubmitting(false);
              actions.resetForm(initialValues);
            }}
          >
            {({ values, actions, handleChange, handleBlur }) => (
              <div>
                <Form
                  style={{ display: this.state.success ? 'none' : 'block' }}
                >
                  <Fieldset
                    classname="form-group names"
                    name="firstName"
                    label="First Name*"
                    type="text"
                    placeholder=""
                  />
                  <Fieldset
                    classname="form-group names"
                    name="lastName"
                    label="Last Name*"
                    type="text"
                    placeholder=""
                  />
                  <Fieldset
                    classname="form-group emailphone"
                    name="email"
                    label="Your Email*"
                    type="email"
                    placeholder=""
                  />
                  <div className="submit-container">
                    <button type="submit" className="red-button">
                      Sign up
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
          <div
            className="thanks-container"
            style={{ display: this.state.success ? 'block' : 'none' }}
          >
            <p className="success-message">{this.state.successMsg}</p>
            <button onClick={this.closeModal} className="red-button">
              Close Window
            </button>
          </div>
        </Modal>
      </Div>
    );
  }
}

export default EmailSignupForm;
