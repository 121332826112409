import React, { Component } from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from '../../style-utilities/variables';
// import components
import MainLogo from './MainLogo';
import MenuToggle from './MenuToggle';
import NavigationMenu from './NavigationMenu';

const StyledHeader = styled.header`
  background-color: white;
  width: 100%;
  border-bottom: 1px solid rgb(221, 221, 221);

  .header-grid {
    max-width: 1500px;
    margin: 0 auto;
    height: 65px;
    display: grid;
    align-items: center;
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: 'MainLogo MainLogo . . MenuToggle MenuToggle';
  }

  /* desktop grid layout for header */
  @media screen and (min-width: ${breakpoints.desktop}) {
    border-bottom: none;
    .header-grid {
      height: 100%;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas: 'MainLogo NavigationMenu NavigationMenu NavigationMenu NavigationMenu NavigationMenu NavigationMenu NavigationMenu NavigationMenu NavigationMenu NavigationMenu NavigationMenu';
    }
  }
`;

class Header extends Component {
  state = {
    menuIsOpen: false,
    isBrowser: true,
    windowObject: null
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined'
    });
    if (this.state.isBrowser) {
      this.setState({ windowObject: window });
    }
  }

  toggleMenu = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen });
    this.state.windowObject.addEventListener('resize', () => {
      this.setState({ menuIsOpen: false });
    });
  };

  hideMenu = () => {
    this.setState({ menuIsOpen: false });
  };

  render() {
    let topPosition = 0;
    if (
      this.props.globals.headline !== '' ||
      this.props.globals.cta_text !== ''
    ) {
      topPosition = '0';
    }
    return (
      <StyledHeader style={{ marginTop: `${topPosition}` }}>
        <div className="header-grid page-container">
          <MainLogo />
          <NavigationMenu
            menuIsOpen={this.state.menuIsOpen}
            menu={this.props.menu}
            toggle={this.toggleMenu}
            hideMenu={this.hideMenu}
          />
          <MenuToggle
            toggleMenu={this.toggleMenu}
            menuIsOpen={this.state.menuIsOpen}
          />
        </div>
      </StyledHeader>
    );
  }
}

export default Header;
