import { combineReducers } from "redux";
// import modules
import homeGridReducer from "./homeGrid";
import tagsReducer from "./tags";
import searchReducer from "./search";

export const rootReducer = combineReducers({
  homeGridReducer,
  tagsReducer,
  searchReducer
});
