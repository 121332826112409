import React, { Component } from 'react';
import { Link } from 'gatsby';
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { breakpoints, colors } from '../style-utilities/variables';

import Arrow from '../components/Arrow';
import MobileSubMenu from '../components/MobileSubMenu';

// component styles
const StyledNavItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  a {
    font-size: 22px;
    font-weight: bold !important;
    color: black;
    display: block;
    padding: 0;
    :hover {
      text-decoration: none;
    }
  }
  .trigger-arrow {
    border-left: 2px solid #ddd;
    padding-left: 25px;
    svg {
      transform: rotate(180deg);
    }
  }

  .back-arrow {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    p {
      font-size: 22px;
      font-weight: bold;
      color: ${colors.primary};
      margin-left: 16px;
      margin-bottom: 0;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    fill: ${colors.primary};
  }

  .mobile-menu {
    position: absolute;
    background: ${colors.secondaryGray};
    top: -70px;
    padding-left: 1rem;
    margin-left: -1rem;
    height: 100vh;
    width: 100vw;
    padding-top: 20px;
    overflow-y: hidden;
    overflow-x: hidden;
    .sub-items {
      padding-left: 36px;
      a {
        padding: 8px 0;
        display: block;
      }
    }
  }

  .mobile-hide {
    z-index: 5;
    transform: translateX(100vw);
    transition: transform 1s cubic-bezier(0, 0, 0, 1);
  }

  .mobile-show {
    z-index: 5;
    transform: translateX(0);
    transition: transform 1s cubic-bezier(0, 0, 0, 1);
  }
  @media (min-width: ${breakpoints.desktop}) {
    height: 100%;
    padding: 0;

    .mobile-show,
    .mobile-hide,
    .mobile-arrow {
      display: none;
    }

    .show,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .dropdown-menu.show,
    .btn-secondary:active,
    .btn-secondary:hover,
    .btn-secondary:focus {
      border: none;
      box-shadow: none;
      border-radius: 0;
      background: ${colors.secondaryGray};
    }

    a {
      width: 100%;
      height: 100%;
    }

    .dropdown-menu,
    .dropdown {
      border: none;
      padding: 0;
      margin: 0;
      border-radius: 0;
      background: white;
      :hover {
        background: ${colors.secondaryGray};
      }
    }

    .dropdown {
      height: 100%;
      .btn-secondary {
        border: none;
        background: transparent;
        color: black;
        height: 100%;
        font-size: 0.875rem;
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 0;
        :hover {
          color: ${colors.primary};
        }
      }
      .dropdown-item {
        width: 100%;
        height: 100%;
        padding: 8px 12px;
        font-size: 0.875rem;
        font-weight: 400 !important;
        :hover {
          background: ${colors.secondaryGray};
          color: ${colors.primary};
          cursor: pointer;
          background: transparent;
        }
      }
      .dropdown-item:first-child {
        padding-top: 14px;
      }

      .dropdown-item:last-child {
        padding-bottom: 14px;
      }
    }
  }
`;

class NavItem extends Component {
  state = {
    isBrowser: true,
    viewPort: 'desktop',
    url: '',
    dropdownOpen: false,
    showSubMenu: ''
  };

  // handles toggling the dropdown state of the menu
  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  //toggles sub menu for nav items with children in mobile view
  toggleSubMenu = () => {
    if (this.state.showSubMenu === '') {
      this.setState({
        showSubMenu: true
      });
    } else {
      this.setState({
        showSubMenu: !this.state.showSubMenu
      });
    }
  };

  hideMenu = () => {
    this.props.hideMenu();
  };

  onMouseEnter = () => {
    this.setState({ dropdownOpen: true });
  };

  onMouseLeave = () => {
    this.setState({ dropdownOpen: false });
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined',
      viewPort: window.innerWidth >= 1025 ? 'desktop' : 'mobile'
    });
    if (this.state.isBrowser) {
      this.setState({ url: window.location.pathname });
      window.addEventListener('resize', this.setViewPort);
    }
  }

  setViewPort = () => {
    this.setState({
      viewPort:
        this.state.isBrowser && window.innerWidth >= 1025 ? 'desktop' : 'mobile'
    });
  };

  render() {
    // destructure props
    const { wordpress_children, title, object_slug } = this.props.item;
    const parent_slug = object_slug;
    // let windowWidth = window.innerWidth;
    let currentPage;

    if (this.state.isBrowser) {
      const pathname = this.state.url.split('/');
      if (pathname[1] === object_slug) {
        currentPage = 'active';
      } else {
        currentPage = '';
      }
    }

    // if the passed in item has children and it is at desktop width, render a dropdown
    if (wordpress_children && this.state.viewPort === 'desktop') {
      return (
        <StyledNavItemContainer className={this.props.className}>
          <Dropdown
            onMouseOver={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            isOpen={this.state.dropdownOpen}
            toggle={this.toggle}
          >
            <Link to={parent_slug} onClick={() => this.hideMenu()}>
              <DropdownToggle className={this.state.dropdownOpen}>
                {title}
              </DropdownToggle>
            </Link>
            <DropdownMenu>
              {wordpress_children.map(({ title, object_slug, index }) => {
                return title == 'Search Jobs' ? (
                  <a
                    className="dropdown-item"
                    href="https://jobs.lever.co/ansira"
                    target="_blank"
                    onClick={() => this.hideMenu()}
                  >
                    {ReactHtmlParser(title)}
                  </a>
                ) : (
                  <Link
                    className="dropdown-item"
                    to={`/${parent_slug}/${object_slug}`}
                    onClick={() => this.hideMenu()}
                  >
                    {ReactHtmlParser(title)}
                  </Link>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </StyledNavItemContainer>
      );
    }

    if (wordpress_children && this.state.viewPort === 'mobile') {
      return (
        <StyledNavItemContainer className={this.props.className}>
          <Link
            to={object_slug}
            className={currentPage}
            onClick={() => this.hideMenu()}
          >
            {title}
          </Link>{' '}
          <div
            className="trigger-arrow"
            onClick={() => this.toggleSubMenu()}
            arrowClass={this.state.showSubMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              viewBox="0 0 1000 1000"
            >
              <path d="M380.5 100.5l-350 350c-27.3 27.3-27.3 71.6 0 99l350 350c27.3 27.3 71.6 27.3 99 0 27.3-27.3 27.3-71.6 0-99L249 570h671c38.7 0 70-31.3 70-70s-31.3-70-70-70H249l230.5-230.5c13.6-13.7 20.5-31.6 20.5-49.5s-6.8-35.8-20.5-49.5c-27.4-27.3-71.6-27.3-99 0z" />
            </svg>
          </div>
          <div
            className={
              this.state.showSubMenu
                ? 'mobile-show mobile-menu'
                : 'mobile-hide mobile-menu'
            }
          >
            <div className="back-arrow" onClick={() => this.toggleSubMenu()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 1000 1000"
              >
                <path d="M380.5 100.5l-350 350c-27.3 27.3-27.3 71.6 0 99l350 350c27.3 27.3 71.6 27.3 99 0 27.3-27.3 27.3-71.6 0-99L249 570h671c38.7 0 70-31.3 70-70s-31.3-70-70-70H249l230.5-230.5c13.6-13.7 20.5-31.6 20.5-49.5s-6.8-35.8-20.5-49.5c-27.4-27.3-71.6-27.3-99 0z" />
              </svg>

              <p>{title}</p>
            </div>
            <div className="sub-items">
              {wordpress_children.map(({ title, object_slug }) => {
                return title == 'Search Jobs' ? (
                  <a
                    href="https://jobs.lever.co/ansira"
                    target="_blank"
                    onClick={() => this.hideMenu()}
                  >
                    {ReactHtmlParser(title)}
                  </a>
                ) : (
                  <Link
                    to={parent_slug + '/' + object_slug}
                    onClick={() => this.hideMenu()}
                  >
                    {title}
                  </Link>
                );
              })}
            </div>
          </div>
        </StyledNavItemContainer>
      );
    }

    if (!wordpress_children) {
      return (
        <StyledNavItemContainer className={this.props.className}>
          <div>
            <Link
              to={object_slug}
              className="no-children"
              onClick={() => this.hideMenu()}
            >
              {title}
            </Link>
          </div>
          <Link
            to={object_slug}
            className="mobile-arrow"
            onClick={() => this.hideMenu()}
          >
            <Arrow />
          </Link>
        </StyledNavItemContainer>
      );
    }
  }
}

export default NavItem;
