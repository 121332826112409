import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors } from '../style-utilities/variables';
const StyledContactButton = styled.span`
  grid-area: ContactButton;
  -ms-grid-column: 13;
  -ms-grid-column-span: 3;
  background-color: ${colors.white};
  color: ${colors.black};
  letter-spacing: 3px;
  align-self: center;
  text-transform: uppercase;
  
  a {
    color: ${colors.black};
    border: 1px solid #ddd;
    border-radius: 40px;
    width: 150px;
    padding: 12px 25px;
    font-weight: bold;
    font-size: 14px;
  }
  a:hover {
    border: 1px solid ${colors.secondaryRed};
    background: ${colors.secondaryRed};
    cursor: pointer;
    color: #fcfcfc;
    text-decoration: none;
    transition: 0.3s all ease;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: flex;
    align-items: center;
    a {
      display: inline-block;
      width: auto;
    }
  }
`;

const ContactButton = () => {
  return (
    <StyledContactButton>
      <Link to="/contact">Contact Us</Link>
    </StyledContactButton>
  );
};

export default ContactButton;
