import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors, breakpoints } from '../style-utilities/variables';
import ArrowLeft from '../components/ArrowLeft';

// component styles
const StyledNavBarContainer = styled.nav`
  .nav-bar {
    align-self: flex-start;
    padding: 3rem 0 1rem 0;

    svg {
      margin-right: 10px;
    }
    a {
      font-weight: 600;
      font-size: 20px;
      font-family: 'daytona-condensed';
      color: ${colors.primary};
      text-decoration: none;
    }
    a:hover {
      color: ${colors.primary};
      text-decoration: underline;
    }
    .left-arrow svg {
      margin-top: -2px;
    }
  }
`;

class NavBar extends Component {
  render() {
    const addedClasses = this.props.className;
    const name = this.props.name;
    const link = this.props.link;
    return (
      <StyledNavBarContainer className="page-container">
        <div className={`nav-bar ${addedClasses}`}>
          <Link to={link} className="back-link">
            <ArrowLeft />
            {name}
          </Link>
        </div>
      </StyledNavBarContainer>
    );
  }
}
export default NavBar;
