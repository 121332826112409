import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../api";
import {
  FETCH_TAG_COLORS_REQUEST,
  FETCH_TAG_COLORS_SUCCESS,
  FETCH_TAG_COLORS_FAILURE
} from "../modules/tags";
import _ from "lodash";

function* fetchTagColors(action) {
  try {
    // fetch tag colors field from wordpress options page
    const tagColorsArray = yield call(Api.fetchTagColors);
    // transform into an object with tags as keys
    const tagColorsObj = _.keyBy(tagColorsArray, item => {
      return item.tag.name;
    });

    yield put({ type: FETCH_TAG_COLORS_SUCCESS, tagColors: tagColorsObj });
  } catch (e) {
    yield put({ type: FETCH_TAG_COLORS_FAILURE, message: e.message });
  }
}

function* tagsSaga() {
  yield takeLatest(FETCH_TAG_COLORS_REQUEST, fetchTagColors);
}

export default tagsSaga;
