import React, { Component } from 'react';
import styled from 'styled-components';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Div = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  .overlay {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
  }
  .flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    margin-top: 0;
  }
  .custom-modal {
    position: relative;
    max-width: 450px;
    padding: 15px;
    box-sizing: border-box;
    background: #fff;
    margin: 0 12px;
    border-radius: 3px;
    z-index: 6;
    color: ${colors.black};
    text-align: left;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    @media (min-width: 460px) {
      padding: 30px;
    }
  }
  .xbutton {
    border: 0;
    background: white;
    cursor: pointer;
    color: ${colors.black};
    width: 27px;
    height: 27px;
    position: absolute;
    right: 10px;
    top: 12px;
    padding: 0;
    outline: none;
    svg {
      font-size: 22px;
    }
  }
`;

class Modal extends Component {
  constructor(props) {
    super(props);
  }

  // render modal
  render() {
    return (
      <Div
        style={{
          display: this.props.isModalOpen ? 'block' : 'none'
        }}
      >
        <div className="overlay">
          <div className="flex-container">
            <div className="custom-modal">
              <button className="xbutton" onClick={this.props.closeModal}>
                <FontAwesomeIcon icon={faTimes} size="1x" />
              </button>
              {this.props.children}
            </div>
          </div>
        </div>
      </Div>
    );
  }
}

export default Modal;
