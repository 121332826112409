import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { breakpoints, colors } from '../../style-utilities/variables';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';
import ansiraLogo from '../../images/ansira-logo.png';
import CloseButton from '../CloseButton';
import Search from '../Search';
import SearchForm from '../SearchForm';
import MainNav from '../MainNav';
import ContactButton from '../ContactButton';

// desktop menu styling
const StyledNavigationMenu = styled.div`
  grid-area: NavigationMenu;
  display: none;

  @media screen and (min-width: ${breakpoints.desktop}) {
    /* define grid */
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(7, 1fr) 1fr auto;
    grid-template-areas: '. MainNav MainNav MainNav MainNav MainNav MainNav MainNav Search ContactButton ContactButton ContactButton';
    -ms-grid-column: 2;
    -ms-grid-column-span: 11;
  }

  @media (min-width: ${breakpoints.desktopMax}) {
    grid-template-columns: repeat(8, 1fr) auto;
    grid-template-areas: '. . MainNav MainNav MainNav MainNav MainNav MainNav MainNav Search ContactButton ContactButton ContactButton';
  }
`;

const StyledMobileNavigationMenu = styled.div`
  background: ${colors.secondaryGray};
  height: 100vh;
  width: 100vw;
  right: -100vw;
  top: 0;
  overflow: scroll;
  position: absolute;
  transition: 0.6s right ease-in;
  &.menu-open {
    opacity: 1;
    right: 0;
    transition: 0.6s right ease-out;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 71px;
    img {
      width: 105px;
      margin-bottom: 0;
    }
    .close-mobile {
      padding: 0;
      background: none;
      border: none;
    }
  }
  a {
    color: ${colors.black};
  }
`;

class NavigationMenu extends Component {
  state = {
    browserWidth: 'desktop'
  };

  componentDidMount() {
    this.setState({
      browserWidth: window.innerWidth > 1025 ? 'desktop' : 'mobile'
    });

    window.addEventListener('resize', () => {
      this.setState({
        browserWidth: window.innerWidth > 1023 ? 'desktop' : 'mobile'
      });
    });
  }

  render() {
    const menuClasses = classNames({
      'menu-open': this.props.menuIsOpen,
      'page-container': true
    });

    return this.state.browserWidth === 'desktop' ? (
      <StyledNavigationMenu>
        <MainNav
          menu={this.props.menu}
          menuIsOpen={this.props.menuIsOpen}
          hideMenu={this.props.hideMenu}
          className="desktop"
        />
        <Search
          className="search"
          placeholder=""
          hideMenu={this.props.hideMenu}
        />
        <ContactButton />
      </StyledNavigationMenu>
    ) : (
      <StyledMobileNavigationMenu className={menuClasses}>
        <div className="mobile-header">
          <Link to="/" onClick={() => this.props.hideMenu()}>
            <img src={ansiraLogo} alt="" />
          </Link>
          {this.props.menuIsOpen ? (
            <button
              className="close-mobile"
              onClick={() => this.props.hideMenu()}
              style={{ color: `${colors.primary}` }}
            >
              <FontAwesomeIcon icon={faTimes} pull="right" size="2x" />
            </button>
          ) : (
            <></>
          )}
        </div>

        <SearchForm
          className="search"
          placeholder="Search our website"
          hideMenu={this.props.hideMenu}
        />

        <MainNav
          menu={this.props.menu}
          menuIsOpen={this.props.menuIsOpen}
          hideMenu={this.props.hideMenu}
          className="mobile"
        ></MainNav>
      </StyledMobileNavigationMenu>
    );
  }
}

export default NavigationMenu;
