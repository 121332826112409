// Action Types
export const FETCH_GRID_ITEMS_REQUEST =
  "ansira/homeGrid/FETCH_GRID_ITEMS";
export const FETCH_GRID_ITEMS_SUCCESS =
  "ansira/homeGrid/FETCH_GRID_ITEMS_SUCCESS";
export const FETCH_GRID_ITEMS_FAILURE =
  "ansira/homeGrid/FETCH_GRID_ITEMS_FAILURE";
export const CLEAR_GRID_ITEMS_STATE =
  "ansira/homeGrid/CLEAR_GRID_ITEMS_STATE";

// Action Creators
export function fetchGridItems(tagId) {
  return {
    type: FETCH_GRID_ITEMS_REQUEST,
    tagId
  };
}

// set initial state
const initialState = {
  gridItems: []
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_GRID_ITEMS_REQUEST:
      return { ...state, requestPending: true };
    case FETCH_GRID_ITEMS_SUCCESS:
      return action.gridItems
        ? {
            gridItems: action.gridItems,
            requestPending: false
          }
        : state;
    default:
      return state;
  }
}
