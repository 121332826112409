/**
 * Ansira Website Theme variables
 */

// colors
export const colors = {
  primary: '#ED3424',
  secondary: '#0d747f',
  secondaryAlt: '#78b9cd',
  secondaryRed: '#BD2229',
  accent: '#e2dc93',
  accentAlt: '#DBEBE1',
  offBlack: '#11181F',
  black: 'black',
  white: '#FFFFFF',
  blue: '#77C5D5',
  blueHover: '#36A2B0',
  primaryGray: '#757575',
  lightGray: '#DAD8D6',
  secondaryGray: '#F2F2F2',
};

// breakpoints
export const breakpoints = {
  mobileSM: '321px',
  iPhone678: '375px',
  mobileMax: '767px',
  tablet: '768px',
  tabletMax: '1024px',
  desktop: '1025px',
  desktopMax: '1225px',
  laptopLG: '1440px',
  max: '1500px'
};

export const fonts = {
  header: 'daytona-condensed',
  subHead: 'daytona-bold',
  body: 'Verdana'
}

// utility
export const mobileMenuWidth = 'calc(100% + 30px)';
