import React from 'react';

const Clarity = () => {
    (function (c,l,a,r,i,t,y) {
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)},
        t=l.createElement(r),
        t.async=1,
        t.src="https://www.clarity.ms/tag/"+i,
        t.id="clarity-ms",
        y=l.getElementsByTagName(r)[0],
        y.parentNode.insertBefore(t,y)
    })(window, document, "clarity", "script", "5kazxwwsvm");
}

export default Clarity;
