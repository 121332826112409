import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { breakpoints, colors } from "../../style-utilities/variables";

const StyledDiv = styled.div`
  padding: 10px 0;
  max-width: 1500px;
  a:hover {
    text-decoration: underline;
  }
  .bottom-item,
  a {
    letter-spacing: 0.75px;
    color: #757575;
    margin-right: 10px;
    margin-top: 10px;
  }
  .second-line {
    display: flex;
  }
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    a {
      margin-right: 30px;
    }
  }
`;

const BottomNav = () => {
  return (
    <StyledDiv className="page-container">
      <div className="bottom-item">
        &copy; {new Date().getFullYear()} Ansira. All Rights Reserved.
      </div>
      <div className="second-line">
        <div className="bottom-item">
          <Link to="/privacy">Privacy</Link>
        </div>
        <div className="bottom-item">
          <a href="tel:8002318179">800-231-8179</a>
        </div>
        <div className="bottom-item">
          <Link to="/contact">Locations</Link>
        </div>
      </div>
    </StyledDiv>
  );
};

export default BottomNav;
