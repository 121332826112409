import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors, breakpoints } from '../../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';

const StyledNavColumnMenu = styled.div`
  height: 100%;
  &:nth-child(odd) {
    border-right: 1px solid #242a30;
  }
  &:nth-child(even) {
    padding-left: 10px;
  }

  h5 {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: ${colors.primaryGray};
    display: block;
  }

  .child-level {
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 0;
    a {
      margin-bottom: 8px;
      display: block;
      color: ${colors.primaryGray};
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 10px;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5) {
      border-right: 1px solid #242a30;
    }
    &:nth-child(3) {
      border-right: none;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    grid-row: span 2;
    border-right: 1px solid #242a30;
    &:nth-child(3) {
      border-right: 1px solid #242a30;
    }
  }
`;

const NavColumnMenu = ({
  menu: { title, object_slug: slug, wordpress_children: children }
}) => {
  return (
    <StyledNavColumnMenu>
      <Link to={`/${slug}`}><h5>{title}</h5></Link>
      {children && (
        <div className="child-level">
          {children.map(({ title, object_slug: slug, parent_slug }) => {
            let menuLink;

            if (slug == 'search-jobs-2') {
              menuLink = (
                <a href={'https://jobs.lever.co/ansira'} target="_blank">
                  {ReactHtmlParser(title)}
                </a>
              );
            } else {
              menuLink = (
                <Link to={`/${parent_slug}/${slug}`}>
                  {ReactHtmlParser(title)}
                </Link>
              );
            }

            return menuLink;
          })}
        </div>
      )}
    </StyledNavColumnMenu>
  );
};

export default NavColumnMenu;
