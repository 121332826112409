import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { breakpoints, colors, fonts } from '../style-utilities/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SearchStateConsumer } from '../context/SearchContext';

const StyledSearchForm = styled.form`
  display: flex;
  /* justify-content: center; */
  input::placeholder {
    color: ${colors.primaryGray};
  }
  input[type='text'] {
    /* Remove styles, must use input[type=text] selector to remove iOS box-shadow styles */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Add styles */
    border-radius: 5px 0 0 5px;
    border-width: 1px 0 1px 1px;
    width: 100%;
    padding: 0 8px;
    height: 2.5rem;
    border-style: solid;
    border-left: 1px solid ${colors.primaryGray};
    border-bottom: 1px solid ${colors.primaryGray};
    border-top: 1px solid ${colors.primaryGray};
    @media(min-width: ${breakpoints.desktop}) {
      max-width: 500px;
    }
  }

  .iconBox {
    width: 2rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.white};
    border-radius: 0 5px 5px 0;
    border-right: 1px solid ${colors.primaryGray};
    border-bottom: 1px solid ${colors.primaryGray};
    border-top: 1px solid ${colors.primaryGray};
    border-right: 1px solid ${colors.primaryGray};
    border-left: 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    overflow: hidden;
    height: 0;
    top: 60px;
    right: -150px;
    padding-right: 160px;
    width: 100vw;
    background: white;

    input[type='text'] {
      width: 90%;
    }
    input::placeholder {
      color: transparent;
    }
    &.search-open {
      height: 2.5em;
      margin-top: 16px;
    }
  }
`;

const SearchForm = props => {
  const { searchIsOpen, toggleSearch, hideMenu } = props;
  const formClass = classNames({
    'search-open': searchIsOpen
  });

  return (
    <SearchStateConsumer>
      {searchContext => {
        return (
          <StyledSearchForm
            onSubmit={event => {
              event.preventDefault();
              searchContext.searchSubmit(searchContext.searchTerm);
              if (typeof toggleSearch === 'function') {
                setTimeout(() => toggleSearch(), 1000);
              }
            }}
            className={formClass}
          >
            <input
              value={searchContext.searchTerm || ''}
              onChange={event => {
                searchContext.updateSearchTerm(event.target.value);
              }}
              type="text"
              placeholder="What would you like to search for?"
            />
            <button type="submit" className="iconBox" onClick={hideMenu}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </StyledSearchForm>
        );
      }}
    </SearchStateConsumer>
  );
};

export default SearchForm;
