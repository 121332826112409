export default {
  fetchGridItems: tagId =>
    // use Promise.all to make requests for different post types
    Promise.all([
      // posts
      fetch(
        `${process.env.GATSBY_API_URI}/wp/v2/posts?_embed=1&per_page=7${
          tagId ? `&tags=${tagId}` : ""
        }`
      ),
      // case studies
      fetch(
        `${process.env.GATSBY_API_URI}/wp/v2/case_study?_embed=1&per_page=7${
          tagId ? `&tags=${tagId}` : ""
        }`
      ),
      //news
      fetch(
        `${process.env.GATSBY_API_URI}/wp/v2/news?_embed=1&per_page=7${
          tagId ? `&tags=${tagId}` : ""
        }`
      ),
      // white papers
      fetch(
        `${process.env.GATSBY_API_URI}/wp/v2/white_paper?_embed=1&per_page=7${
          tagId ? `&tags=${tagId}` : ""
        }`
      )
    ])
      // use Promise.all to wait for .json() to complete on each response
      .then(responses => Promise.all(responses.map(res => res.json())))
      .then(arrays => {
        // combine arrays from response
        const combinedArrays = [].concat.apply([], arrays);
        // return combinedArray sorted from newest to oldest
        return combinedArrays.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
      }),
  fetchTagColors: () =>
    fetch(`${process.env.GATSBY_API_URI}/acf/v2/options`)
      .then(res => res.json())
      .then(json => json.acf.tag_colors),
  siteSearch: searchTerm =>
    // use Promise.all to make requests for different post types
    Promise.all([
      // page
      fetch(`${process.env.GATSBY_API_URI}/wp/v2/pages?search=${searchTerm}`
      ),
      // posts
      fetch(`${process.env.GATSBY_API_URI}/wp/v2/posts?search=${searchTerm}`
      ),
      // case study
      fetch(
        `${process.env.GATSBY_API_URI}/wp/v2/case_study?search=${searchTerm}`
      ),
      // news
      fetch(`${process.env.GATSBY_API_URI}/wp/v2/news?search=${searchTerm}`
      ),
      // white paper
      fetch(
        `${process.env.GATSBY_API_URI}/wp/v2/white_papers?search=${searchTerm}`
      ),
      // authors
      fetch(
        `${process.env.GATSBY_API_URI}/wp/v2/users/?fist_name=${searchTerm}`
      )
    ])
      // use Promise.all to wait for .json() to complete on each response
      .then(responses => Promise.all(responses.map(res => res.json())))
      .then(results => {
        // combine arrays from response
        return [].concat.apply([], results);
      })
};
