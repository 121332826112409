// require stylesheets for prod build
// wrap root element with redux provider
import wrapWithProvider from "./src/redux/wrap-with-provider";
export const wrapRootElement = wrapWithProvider;
// import 'whatwg-fetch';
// export const onClientEntry = () => {
//   require('bootstrap/dist/css/bootstrap.min.css');
//   require('./src/style-utilities/layout.css');
//   require('./src/style-utilities/base.css');
// };
