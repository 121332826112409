import React, { Component } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import variables
import { breakpoints, colors } from "../style-utilities/variables";
// import components
import Arrow from "../components/Arrow";

const StyledMobileSubMenu = styled.button`
  position: relative;
  .slider-section {
    margin-top: 4em;
  }
  .slider-section.board {
    position: relative;
  }
  .slider-section.board.show-tab {
    height: 100vh;
  }
  .slider-container {
    .hide {
      transform: translate3d(100vw, 0, 0);
      transition: transform 0.5s cubic-bezier(0, 0, 0, 1);
      height: 0;
      .circle {
        display: none;
      }
    }
    .show {
      transform: translate3d(0, 0, 0);
      transition: transform 0.5s cubic-bezier(0, 0, 0, 1);
      height: 100%;
      padding: 4em 1em;
    }
  }
`;
StyledMobileSubMenu.displayName = "StyledMobileSubMenu";

class MobileSubMenu extends Component {


  render() {

    return (
      <StyledMobileSubMenu>

        {/* selectedParent={parent.slug} */}
        <FontAwesomeIcon icon={faTimes} pull="right" size="2x" />
      </StyledMobileSubMenu>
    );
  }
}

export default MobileSubMenu;
