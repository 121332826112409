import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../api";
import {
  FETCH_GRID_ITEMS_REQUEST,
  FETCH_GRID_ITEMS_SUCCESS,
  FETCH_GRID_ITEMS_FAILURE
} from "../modules/homeGrid";

function* fetchGridItems(action) {
  try {
    const gridItems = yield call(Api.fetchGridItems, action.tagId);

    yield put({ type: FETCH_GRID_ITEMS_SUCCESS, gridItems: gridItems });
  } catch (e) {
    yield put({ type: FETCH_GRID_ITEMS_FAILURE, message: e.message });
  }
}

function* gridItemsSaga() {
  yield takeLatest(FETCH_GRID_ITEMS_REQUEST, fetchGridItems);
}

export default gridItemsSaga;
