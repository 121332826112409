import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { colors, breakpoints } from "../style-utilities/variables";

const StyledArrow = styled.a`
  svg {
    width: 16px;
    height: 16px;
    fill: ${colors.primary};
    transform: rotate(180deg);
  }
`;

const Arrow = ({arrowClass, pageLink}) => {
  return (
    <StyledArrow className={arrowClass + " arrow"} href={pageLink}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 1000 1000"
      >
        <path d="M380.5 100.5l-350 350c-27.3 27.3-27.3 71.6 0 99l350 350c27.3 27.3 71.6 27.3 99 0 27.3-27.3 27.3-71.6 0-99L249 570h671c38.7 0 70-31.3 70-70s-31.3-70-70-70H249l230.5-230.5c13.6-13.7 20.5-31.6 20.5-49.5s-6.8-35.8-20.5-49.5c-27.4-27.3-71.6-27.3-99 0z" />
      </svg>
    </StyledArrow>
  );
};

export default Arrow;
