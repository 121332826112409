import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../api";
import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE
} from "../modules/search";

function* siteSearch(action) {
  try {
    const searchResults = yield call(Api.siteSearch, action.searchTerm);
    yield put({ type: SEARCH_SUCCESS, searchResults });
  } catch (e) {
    yield put({ type: SEARCH_FAILURE, message: e.message });
  }
}

function* searchSaga() {
  yield takeLatest(SEARCH_REQUEST, siteSearch);
}

export default searchSaga;
