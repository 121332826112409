import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { breakpoints } from '../../style-utilities/variables';
import ansiraLogo from '../../images/ansira-logo.png';

const StyledMainLogo = styled.div`
  grid-area: MainLogo;
  align-self: center;
  grid-column: 1 / span 2;
  height: 100%;
  margin-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-self: start;
  @media (max-width: ${breakpoints.tablet}) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    border-right: 0;
  }
  .img-div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    img {
      margin-bottom: 0px;
      width: 100px;
    }
    a {
      width: 100%;
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 79px;
  }
`;

const MainLogo = () => {
  return (
    <StyledMainLogo>
      <Link to="/">
        <div className="img-div">
          <img src={ansiraLogo} alt="Ansira Logo" />
        </div>
      </Link>
    </StyledMainLogo>
  );
};

export default MainLogo;
