import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { fetchGridItems } from '../redux/modules/homeGrid';
import { fetchTagColors } from '../redux/modules/tags';
import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
// import components
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style-utilities/layout.css';
import '../style-utilities/base.css';
import '../style-utilities/hubspotModal.css';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import NavBar from '../components/NavBar';
import GlobalAlertBanner from '../components/GlobalAlertBanner';
import CookieConsentBanner from '../components/CookieConsentBanner';
import SearchState from '../context/SearchContext';
import Clarity from '../components/ClarityMS.js';
//favicon setup
import favicon16 from '../static/favicon-16x16.png';
import favicon32 from '../static/favicon-32x32.png';
import faviconApple from '../static/apple-touch-icon.png';
import favicon from '../static/favicon.ico';
// import faviconMSTile from '../static/mstile-150x150.png';
// import faviconAndroid from '../static/android-chrome-144x144.png';
//import manifestDoc from ".static/site.webmanifest";
import maskIcon from '../static/safari-pinned-tab.svg';
import loading from '../images/spinner.gif';
//import browserConfig from "./static/browserconfig.xml";

// set up context to pass props any level down
const AppContext = React.createContext();
const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;

class Layout extends Component {
  state = {
    isBrowser: true
  };
  // constructor() {
  //   super();
  // }

  hubSpotObserver() {
    const targetNode = document.body;
    const targetParent = 'leadinModal-preview';
    const targetFormless = 'leadinModal-formless';
    const config = {attributes: true, childList: true, subtree: true };

    const callback = (mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes'
        && mutation.attributeName === 'class'
        && !mutation.target.classList.contains(targetFormless)
        && mutation.target.classList.contains(targetParent)) {
          skipCallout();
        }
      }
    }

    const skipCallout = () => {
      let leadinModalPreview = targetNode.querySelector('.'+targetParent);
      let preview = leadinModalPreview.querySelector('.leadin-advance-button');
      preview.click();
      observer.disconnect();
    }

    const observer = new MutationObserver(callback);

    observer.observe(targetNode, config);
  }

  // call action creators for dynamic data
  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined'
    });
    // fetch grid items for content grid when app mounts
    this.props.fetchGridItems();
    // fetch colors associated with various tags when app mounts
    this.props.fetchTagColors();
    this.setState({ loadingClass: '' });
    this.hubSpotObserver();
    // load Clarity MS tracker into head
    Clarity();
  }

  render() {
    // if (this.state.isBrowser) {
    // eslint-disable-next-line global-require
    //   require('smooth-scroll')('a[href*="#"]');
    // }
    const { children } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query HeaderDataQuery {
            site {
              siteMetadata {
                title
                siteUrl
              }
            }
            allWordpressWpApiMenusMenusItems {
              edges {
                node {
                  name
                  items {
                    title
                    object_slug
                    wordpress_children {
                      title
                      url
                      object_slug
                    }
                  }
                }
              }
            }
            allWordpressAcfOptions {
              edges {
                node {
                  options {
                    body_text
                    button_text
                    headline
                    cta_text
                    cta_url
                    headline_copy
                    cta_text_copy
                    cta_url_copy
                    tag_colors {
                      tag {
                        name
                      }
                      color
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          // set route variable to the current route
          // this is used for breadcrumbs
          const route = this.props.location.pathname;
          // pull menus from data
          // these are navigation menus, used in header and footer
          const {
            allWordpressWpApiMenusMenusItems: { edges: menus }
          } = data;
          const headerMenu = menus.find(({ node }) => {
            return node.name === 'Primary Nav';
          });
          const footerMenu = menus.find(({ node }) => {
            return node.name === 'Footer Nav';
          });
          // pull tagColors out of data
          // tag colors are used in various places through
          // the site - mostly on Post Cards
          const {
            tag_colors
          } = data.allWordpressAcfOptions.edges[0].node.options;
          const tagColorsObj = _.keyBy(tag_colors, item => {
            return item.tag.name;
          });

          const globals = data.allWordpressAcfOptions.edges[0].node.options;

          // const isBrowser = typeof window !== 'undefined';
          if (this.state.isBrowser) {
            return (
              <>
                <SearchState>
                  <Helmet
                    title={data.site.siteMetadata.title}
                    meta={[
                      { property: 'og:type', content: 'website' },
                      {
                        property: 'og:title',
                        content: data.site.siteMetadata.title
                      },
                      {
                        property: 'og:url',
                        content: data.site.siteMetadata.siteUrl
                      },
                      { property: 'og:description', content: '' },
                      { name: 'description', content: '' },
                      { name: 'keywords', content: '' },
                      {
                        name: 'viewport',
                        content:
                          'width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0'
                      },
                      { name: 'msapplication-TileColor', content: '#da532c' },
                      {
                        name: 'msapplication-config',
                        content: '/static/browserconfig.xml'
                      },
                      { name: 'theme-color', content: '#ffffff' },
                      {
                        name: 'msvalidate.01',
                        content: '2272CA3E8687981D3E8BA77E4B91BEB1'
                      }
                    ]}
                    link={[
                      {
                        rel: 'stylesheet',
                        href:
                          'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.4.1/css/swiper.css'
                      },
                      {
                        rel: 'apple-touch-icon',
                        sizes: '180x180',
                        href: faviconApple
                      },
                      {
                        rel: 'icon',
                        sizes: '32x32',
                        href: favicon32
                      },
                      {
                        rel: 'icon',
                        sizes: '16x16',
                        href: favicon16
                      },
                      {
                        rel: 'shortcut icon',
                        href: favicon
                      },
                      {
                        rel: 'mask-icon',
                        color: '#5bbad5',
                        href: maskIcon
                      },
                      {
                        rel: 'manifest',
                        href: '/static/site.webmanifest'
                      }
                    ]}
                  >
                    <script type="text/javascript" language="javascript">
                      var sf14gv = 17596; var sf14g =
                      document.createElement('script'); sf14g.src =
                      'https://tracking.leadlander.com/lt.min.js'; var s =
                      document.getElementsByTagName('script')[0];
                      s.parentNode.insertBefore(sf14g, s);
                    </script>
                    <script>
                      
                    </script>
                    <html lang="en" />
                  </Helmet>
                  {/* pass tagColors from Global Options in WP for use in the app */}
                  <AppContextProvider value={{ tagColors: tagColorsObj }}>
                    <div class="header-container">
                      <GlobalAlertBanner content={globals} />
                      <Header menu={headerMenu.node.items} globals={globals} />
                    </div>
                    <div className="site">
                      <div className="site-content">{children}</div>
                      <Footer menu={footerMenu.node.items} />
                      <CookieConsentBanner content={globals} />
                    </div>
                  </AppContextProvider>
                </SearchState>
              </>
            );
          }
        }}
      />
    );
  }
}

export default connect(null, { fetchGridItems, fetchTagColors })(Layout);
